<template>
  <div  class="manager-container">
    <div class="query-item button-bar">
      <el-button type="danger" round @click="handleClearCacheClick()">
        Clear Cache
      </el-button>
      <el-button type="danger" round @click="handleMapMpaLocationClick()">
        Map MPA Location
      </el-button>
      <el-button
        v-if="hasPermission($permissionFunctionCode.ADD)"
        round
        type="primary"
        @click="selectedParentId = null; locationDialogVisible = true;">
        Create Location and Berth
      </el-button>
    </div>
    <div class="table-block">
      <el-table
        v-loading="tableLoading"
        :data="locationTableData"
        height="100%"
        row-key='id'
        :expand-row-keys="expandedRowsKey"
        @expand-change="handleChange"
        :border="true"
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="CELL_STYLE"
      >
      <el-table-column label=""  min-width="1px">
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
                :data="props.row.locations"
                :cell-style="CELL_STYLE"
                :header-cell-style="{  fontSize: '12px' }" >
                <el-table-column label="Name" min-width="120px">
                  <template v-slot="scope">
                    <p>{{scope.row.name}}</p>
                  </template>
                </el-table-column>
                <el-table-column label="Updated At" min-width="120px" >
                  <template v-slot="scope">
                     <p>{{scope.row.updatedAt}}</p>
                  </template>
                </el-table-column>
                <el-table-column label="MPA Code" min-width="180px">
                  <template v-slot="scope">
                    <el-select
                      filterable
                      clearable
                      style="width: 100%"
                      v-model="scope.row.mpaCode"
                      placeholder="Please enter a keyword"
                      remote
                      :remote-method="searchMpaLocations"
                      @change="handleEditMpaCode(scope.row)"
                      @visible-change="(isVisible) => handleVisibleChangeMpaCode(isVisible, scope.row)"
                    >
                      <el-option
                        v-for="item in mpaCodeOptions"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column width="60px">
                <template label="" v-slot="scope">
                  <div>
                    <el-popconfirm
                      placement="left-start"
                      confirm-button-text='Delete'
                      cancel-button-text='No, Thanks'
                      icon="el-icon-info"
                      icon-color="red"
                      title="Are you sure to delete this?"
                      @confirm="deleteRow(scope.row)"
                    >
                      <el-button slot="reference" size="small" icon="el-icon-delete" circle></el-button>
                    </el-popconfirm>
                  </div>
                </template>
                </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="Location Name"  min-width="200px">
            <template v-slot="scope">
                <p>{{scope.row.name}}</p>
          </template>
        </el-table-column>
        <el-table-column label="Total No. of Locations" min-width="150px">
          <template v-slot="scope">
              <p>{{ scope.row.locations ? scope.row.locations.length : 0 }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Updated At" min-width="120px">
          <template v-slot="scope">
              <p>{{scope.row.updatedAt}}</p>
          </template>
        </el-table-column>
        <el-table-column width="120px">
          <template v-slot="scope">
            <div class="flex-between-row">
              <el-button  @click={onAddNew(scope.row)} size="small" icon="el-icon-plus" circle></el-button>
              <el-popconfirm
                placement="left-start"
                confirm-button-text='Delete'
                cancel-button-text='No, Thanks'
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure to delete this?"
                @confirm="deleteParentRow(scope.row)"
              >
                <el-button slot="reference" size="small" icon="el-icon-delete" circle></el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <add-terminal-and-berth
      v-if="locationDialogVisible"
      @closed="locationDialogVisible = false"
      :parentId="selectedParentId"
   />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { CELL_STYLE, HEADER_CELL_STYLE } from '@/constants.js'
import AddTerminalAndBerth from './AddTerminalAndBerth.vue'
import { capitalizeFirstLetter } from '@/utils/index.js'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
export default {
  name: 'LocationList',
  mixins: [baseMixin, baseTableMixin],
  components: { AddTerminalAndBerth },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      tableLoading: false,
      locationDialogVisible: false,
      selectedParentId: null,
      expandedRowsKey: [],
      mpaCodeList: [],
      mpaCodeOptions: []
    }
  },
  created () {
    this.deleteUrl = this.$apis.location
    this.$request.get({
      url: this.$apis.mpaBunkerLocation
    }).then(res => {
      if (res?.code === 1000) {
        this.mpaCodeList = res.data || []
      }
    })
  },

  computed: {
    ...mapState(['currentCompany', 'locationList']),
    locationTableData () {
      if (this.locationList) {
        return this.locationList.map(p => {
          return {
            id: p.id,
            name: p.name,
            updatedAt: this.$moment(p.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
            locations: p.children.map(c => {
              return {
                id: c.id,
                name: c.name,
                updatedAt: this.$moment(c.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
                parentId: c.parentId,
                mpaCode: c.mpaCode,
                mpaDescription: c.mpaDescription
              }
            })
          }
        })
      }
    },
  },
  methods: {
    capitalizeFirstLetter,
    onAddNew (item) {
      this.selectedParentId = item.id
      this.locationDialogVisible = true
    },
    deleteCallBack (message) {
      if (message === 'success') {
        this.$store.dispatch('getLocationList')
      }
    },
    deleteRow (row, rowIndex) {
      this.deleteSingleDataByIdMixin(row.id, this.deleteCallBack)
    },
    deleteParentRow (row, rowIndex) {
      if (row?.children?.length > 0) {
        this.$message({
          message: 'Unable to delete when it is not empty',
          type: 'warning'
        })
      } else {
        this.deleteRow(row)
      }
    },
    handleChange (selection, expandedRows) {
      if (expandedRows.length === 0) {
        this.expandedRowsKey = []
      } else if (expandedRows.length > 1) {
        this.expandedRowsKey = [selection.id]
      } else {
        this.expandedRowsKey.push(selection.id)
      }
    },
    handleClearCacheClick () {
      this.$request.delete(this.$apis.mpaBunkerLocation).then(res => {
        if (res?.code === 1000) {
          this.$message.success('MPA location cache cleared')
        }
      })
    },
    handleEditMpaCode (rowData) {
      this.$request.put({
        url: this.$apis.location,
        data: rowData
      }).then(res => {
        if (res?.code === 1000) {
          this.$store.dispatch('getLocationList').then(() => {
            this.$message.success('Location updated successfully')
          })
        }
      }).finally(() => {
        this.mpaCodeOptions = []
      })
    },
    handleMapMpaLocationClick () {
      this.$request.post({
        url: this.$apis.location + '/map_mpa_location'
      }).then(res => {
        if (res?.code === 1000) {
          this.$store.dispatch('getLocationList').then(() => {
            this.$message.success('Location mapped successfully')
          })
        }
      })
    },
    handleVisibleChangeMpaCode (isVisible, data) {
      if(isVisible) {
        setTimeout(() => this.searchMpaLocations(data.mpaCode), 300)
      }
      else {
        this.mpaCodeOptions = []
      }
    },
    searchMpaLocations (query) {
      if(!query || query.length <= 2) {
        this.mpaCodeOptions = []
        return
      }

      this.mpaCodeOptions = this.mpaCodeList
      .filter(i => i.locationCode.toLowerCase().includes(query.toLowerCase()) || i.locationDescription.toLowerCase().includes(query.toLowerCase()))
      .map(i => {
        return {
          key: i.locationCode,
          value: i.locationCode,
          label: i.locationCode ? `${i.locationCode.trim()} (${i.locationDescription.trim()})` : 'Select'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.manager-container {
  height: 100vh;
  width: 100%;

.table-block {
  height: calc(100% - 200px);
}
.button-bar {
  padding: 8px 0;
  float: right;
}
}

</style>
