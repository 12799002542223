var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _c(
        "div",
        { staticClass: "query-item button-bar" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleClearCacheClick()
                },
              },
            },
            [_vm._v(" Clear Cache ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleMapMpaLocationClick()
                },
              },
            },
            [_vm._v(" Map MPA Location ")]
          ),
          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.selectedParentId = null
                      _vm.locationDialogVisible = true
                    },
                  },
                },
                [_vm._v(" Create Location and Berth ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: {
                data: _vm.locationTableData,
                height: "100%",
                "row-key": "id",
                "expand-row-keys": _vm.expandedRowsKey,
                border: true,
                "header-cell-style": _vm.HEADER_CELL_STYLE,
                "cell-style": _vm.CELL_STYLE,
              },
              on: { "expand-change": _vm.handleChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "", "min-width": "1px" },
              }),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: props.row.locations,
                              "cell-style": _vm.CELL_STYLE,
                              "header-cell-style": { fontSize: "12px" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "Name", "min-width": "120px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(_vm._s(scope.row.name)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Updated At",
                                "min-width": "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("p", [
                                          _vm._v(_vm._s(scope.row.updatedAt)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "MPA Code",
                                "min-width": "180px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              clearable: "",
                                              placeholder:
                                                "Please enter a keyword",
                                              remote: "",
                                              "remote-method":
                                                _vm.searchMpaLocations,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleEditMpaCode(
                                                  scope.row
                                                )
                                              },
                                              "visible-change": (isVisible) =>
                                                _vm.handleVisibleChangeMpaCode(
                                                  isVisible,
                                                  scope.row
                                                ),
                                            },
                                            model: {
                                              value: scope.row.mpaCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "mpaCode",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.mpaCode",
                                            },
                                          },
                                          _vm._l(
                                            _vm.mpaCodeOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.key,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { width: "60px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-popconfirm",
                                              {
                                                attrs: {
                                                  placement: "left-start",
                                                  "confirm-button-text":
                                                    "Delete",
                                                  "cancel-button-text":
                                                    "No, Thanks",
                                                  icon: "el-icon-info",
                                                  "icon-color": "red",
                                                  title:
                                                    "Are you sure to delete this?",
                                                },
                                                on: {
                                                  confirm: function ($event) {
                                                    return _vm.deleteRow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    slot: "reference",
                                                    size: "small",
                                                    icon: "el-icon-delete",
                                                    circle: "",
                                                  },
                                                  slot: "reference",
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Location Name", "min-width": "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("p", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Total No. of Locations",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              scope.row.locations
                                ? scope.row.locations.length
                                : 0
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Updated At", "min-width": "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("p", [_vm._v(_vm._s(scope.row.updatedAt))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex-between-row" },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "small",
                                icon: "el-icon-plus",
                                circle: "",
                              },
                              on: {
                                click: function ($event) {
                                  {
                                    _vm.onAddNew(scope.row)
                                  }
                                },
                              },
                            }),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  placement: "left-start",
                                  "confirm-button-text": "Delete",
                                  "cancel-button-text": "No, Thanks",
                                  icon: "el-icon-info",
                                  "icon-color": "red",
                                  title: "Are you sure to delete this?",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteParentRow(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "reference",
                                    size: "small",
                                    icon: "el-icon-delete",
                                    circle: "",
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.locationDialogVisible
        ? _c("add-terminal-and-berth", {
            attrs: { parentId: _vm.selectedParentId },
            on: {
              closed: function ($event) {
                _vm.locationDialogVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }